import { Grid, Button, TextField, Typography, IconButton, Autocomplete, InputAdornment } from "@mui/material";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import ErrorBox from "../components/Common/ErrorBox";
import { UserContext } from "../context/UserContext";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { combineDateTime, axiosInstance } from "../utils/utils";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import TopBar from "../components/Layout/TopBar";
import { CalendarIcon, TimeIcon } from "@mui/x-date-pickers";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import DeleteDialog from "../components/Common/DeleteDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";

const EditCheckout = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [, setModified] = useState(false);
    const [items, setItems] = useState([{ name: "", quantity: 1, image: false }]);
    const [images, setImages] = useState([null]);
    const [uploads, setUploads] = useState([null]);
    const [details, setDetails] = useState({ name: "", company: "", project: "", notes: "", date: dayjs(new Date()), time: dayjs(new Date()) });
    const [nameOptions, setNameOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [itemNameOptions, setItemNameOptions] = useState([]);
    const headingParams = { variant: "body1", sx: { fontWeight: "bold", color: "#495464", mr: 1 } };
    const [opendate, setOpenDate] = useState(false);
    const [opentime, setOpenTime] = useState(false);
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: true, autoComplete: true, autoHighlight: true, blurOnSelect: true };
    const topRef = useRef(null);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const lastItemRef = useRef();
    const pickerSlotProps = (setOpen, Icon, name, format, workArea) => {
        return ({
            textField: {
                InputProps: { placeholder: null, value: details[name] ? dayjs(details[name]).format(format) : "", endAdornment: (<InputAdornment position="end" onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}><Icon /></InputAdornment>) },
                readOnly: true,
                sx: { input: { cursor: "pointer" }, width: "100%" },
                onClick: () => setOpen(true),
                InputLabelProps: { shrink: true }
            },
            actionBar: {
                actions: ["clear", "accept"],
            }
        });
    };
    const [itemsModified, setItemsModified] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const nameRef = useRef();
    const projectRef = useRef();
    const itemNameRef = useRef();
    const itemQuantityRef = useRef();
    const [selectedItemIndex, setSelectedItemIndex] = useState(false);
    const addingItem = useRef(false);

    useEffect(() => {
        const date = dayjs(new Date()).subtract(5, "month").toISOString().substring(0, 10);

        const fetchNameOptions = () => {
            const url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=name&start_date=" + date;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setNameOptions(res.data);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        const fetchProjectOptions = () => {
            const url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=project&start_date=" + date;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setProjectOptions(res.data);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        const fetchItemNameOptions = () => {
            const url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=itemName&start_date=" + date;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setItemNameOptions(res.data);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchNameOptions();
        fetchProjectOptions();
        fetchItemNameOptions();
    }, [userContext, setUserContext]);

    const handleDateChange = (name, value) => {
        if (!Date.parse(value)) {
            handleChange(name, "");
        } else {
            handleChange(name, value);
        }

        setModified(true);
    };

    const handleChange = (name, value) => {
        setDetails(pairs => ({ ...pairs, [name]: value }));
        setModified(true);
    };

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return details.name && items.length > 0 && items.every(item => item.name && item.quantity);
        };

        setDisableSubmitButton(!fieldsNotEmpty());
    }, [details, items]);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        let url = process.env.REACT_APP_API_URL + "/checkouts/" + params._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const payload = { ...details };

        // Combine date and time
        const date = combineDateTime(details.date, details.time);

        payload.date = date;
        delete payload.time;

        if (!itemsModified) {
            delete payload.items;
        } else {
            if (items.length === 0) {
                payload.items = "";
            } else {
                payload.items = items;
            }
        }

        payload.images = uploads;

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                navigate("/main", { replace: true, state: { snackbarMessage: "Checkout modified" } });
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
            });
    };

    const handleItemChange = (name, value, index) => {
        let updatedItems = [...items];
        updatedItems[index][name] = value;
        setItems(updatedItems);
        setItemsModified(true);
        setModified(true);
    };

    const handleRemoveItem = index => {
        let updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);

        let updatedUploads = [...uploads];
        updatedUploads.splice(index, 1);
        setUploads(updatedUploads);

        let updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);

        setItemsModified(true);
        setModified(true);
    };

    const handleAddItem = () => {
        addingItem.current = true;
        setItems(items => [...items, { name: "", quantity: 1, image: false }]);
        setImages(images => [...images, null]);
        setUploads(uploads => [...uploads, null]);
        setItemsModified(true);
        setModified(true);
    };

    const handleImageChange = (event, value, index) => {
        if (event.target.files.length === 0) return;

        let updatedItems = [...items];
        updatedItems[index].image = true;
        setItems(updatedItems);

        let updatedUploads = [...uploads];
        updatedUploads[index] = event.target.files[0];
        setUploads(updatedUploads);

        let updatedImages = [...images];
        updatedImages[index] = URL.createObjectURL(event.target.files[0]);
        setImages(updatedImages);

        setItemsModified(true);
        setModified(true);
    };

    useEffect(() => {
        if (items.length === 0) {
            handleAddItem();
        } else if (addingItem.current) {
            lastItemRef.current.scrollIntoView();
            addingItem.current = false;
        }
    }, [items]);

    const getImage = useCallback(image => {
        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    }, [userContext.token]);

    useEffect(() => {
        const fetchEntry = () => {
            let url = process.env.REACT_APP_API_URL + "/checkouts/" + params._id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    const date = res.data.date ? dayjs(res.data.date) : dayjs(new Date());

                    const fetchedDetails = res.data;

                    if (res.data.date) {
                        fetchedDetails.date = date;
                        fetchedDetails.time = date;
                    }

                    if (res.data.items) {
                        let fetchedImages = [];
                        let fetchedUploads = [];

                        fetchedDetails.items.forEach((item, index) => {
                            if (item.image) {
                                const fetchedImage = getImage(item.image);

                                fetchedImages.push(fetchedImage);

                                fetch(fetchedImage)
                                    .then(res => {
                                        res.blob().then(imgBlob => {
                                            const imgFile = new File([imgBlob], "image", { type: "image/jpeg" });
                                            fetchedUploads[index] = imgFile;
                                        });
                                    });

                                item.image = true;
                            } else {
                                fetchedImages.push(null);
                                fetchedUploads[index] = null;
                                item.image = false;
                            }
                        });

                        setImages(fetchedImages);
                        setUploads(fetchedUploads);
                        setDetails(details => ({ ...details, ...fetchedDetails }));
                        setItems(fetchedDetails.items);
                    }
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntry();
    }, [params, userContext, setUserContext, getImage]);

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/checkouts/" + params._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                navigate("/main", { replace: true, state: { snackbarMessage: "Checkout deleted" } });
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    useEffect(() => {
        if (!topRef.current) return;
        topRef.current.scrollIntoView();
    }, [topRef]);

    useEffect(() => setIsLoading(!details._id), [details]);

    const isTouchScreenDevice = () => {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (err) {
            return false;
        }
    };

    return (!isLoading &&
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TopBar title="Edit Checkout" enableBack modified={false} backRoute={-1} />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">What would you like to change?</Typography>
            </Grid>
            <Grid container item justifyContent="center">
                <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "650px" }}>
                    <Grid container item spacing={4} sx={{ mb: 1.5 }}>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Date & Time</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5}>
                            <Grid item sx={{ width: "58%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={"Date"}
                                        open={opendate}
                                        onClose={() => setOpenDate(false)}
                                        value={details.date}
                                        onChange={value => handleDateChange("date", value)}
                                        sx={{ width: "100%" }}
                                        slotProps={pickerSlotProps(setOpenDate, CalendarIcon, "date", "ddd, MM/DD/YYYY")}
                                        maxDate={dayjs(new Date())}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item sx={{ width: "42%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label={"Time"}
                                        open={opentime}
                                        onClose={() => setOpenTime(false)}
                                        value={details.time}
                                        onChange={value => handleDateChange("time", value)}
                                        sx={{ width: "100%" }}
                                        slotProps={pickerSlotProps(setOpenTime, TimeIcon, "time", "hh:mm a")}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Details</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item ref={nameRef}>
                            <Autocomplete
                                {...autocompleteParams}
                                options={nameOptions}
                                renderInput={params => (<TextField
                                    {...params}
                                    label="Employee"
                                    value={details.name}
                                    inputProps={{ ...params.inputProps, autoCapitalize: "words" }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText="Example: Tam Luu"
                                />)}
                                onInputChange={(event, value) => handleChange("name", value)}
                                inputValue={details.name}
                                onOpen={() => {
                                    if (isTouchScreenDevice()) {
                                        setTimeout(() => {
                                            nameRef.current.style.marginBottom = "90vh";
                                            nameRef.current.scrollIntoView();
                                        }, 100);
                                    }
                                }}
                                onClose={() => nameRef.current.style.marginBottom = "0"}
                            />
                        </Grid>
                        <Grid container item ref={projectRef}>
                            <Autocomplete
                                {...autocompleteParams}
                                options={projectOptions}
                                renderInput={params => (<TextField
                                    {...params}
                                    label="Project"
                                    value={details.project}
                                    inputProps={{ ...params.inputProps, autoCapitalize: "words" }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText="Example: Double Down"
                                />)}
                                onInputChange={(event, value) => handleChange("project", value)}
                                inputValue={details.project}
                                onOpen={() => {
                                    if (isTouchScreenDevice()) {
                                        setTimeout(() => {
                                            projectRef.current.style.marginBottom = "90vh";
                                            projectRef.current.scrollIntoView();
                                        }, 100);
                                    }
                                }}
                                onClose={() => projectRef.current.style.marginBottom = "0"}
                            />
                        </Grid>
                        <Grid container item>
                            <Autocomplete
                                {...autocompleteParams}
                                options={[]}
                                renderInput={params => (<TextField
                                    {...params}
                                    multiline
                                    rows={3}
                                    label="Notes"
                                    value={details.notes}
                                    inputProps={{ ...params.inputProps, autoCapitalize: "sentences" }}
                                    InputLabelProps={{ shrink: true }}
                                />)}
                                onInputChange={(event, value) => handleChange("notes", value)}
                                inputValue={details.notes}
                                blurOnSelect={false}
                            />
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Items</Typography>
                            </Grid>
                            <Grid container item direction="column" spacing={2.5}>
                                <Grid container item>
                                    {items.map((item, index) => {
                                        return (
                                            <Grid container item key={index} spacing={2.5} sx={{ mt: index > 0 ? 0 : null }} ref={index === items.length - 1 ? lastItemRef : null}>
                                                <Grid item>
                                                    <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px", fontWeight: "bold" }}>
                                                        Item #{index + 1}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item spacing={2.5}>
                                                    <Grid container item direction="row" spacing={2.5} xs={10}>
                                                        <Grid item xs={12}>
                                                            {item.image ?
                                                                <label>
                                                                    <input hidden type="file" accept="image/*" onChange={(event, value) => handleImageChange(event, value, index)} onClick={event => event.target.value = null} />
                                                                    <img
                                                                        src={images[index]}
                                                                        alt=""
                                                                        width="100%"
                                                                        style={{ objectFit: "cover", aspectRatio: 1, cursor: "pointer" }}
                                                                    />
                                                                </label>
                                                                :
                                                                <div style={{ width: "100%", borderRadius: "5px", marginBottom: "7px" }}>
                                                                    <Grid container item sx={{ alignContent: "center", height: "300px", borderRadius: "5px", border: "2px dotted #495464", cursor: "pointer" }}>
                                                                        <Grid container item justifyContent="center">
                                                                            <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>Click to add item image</Typography>
                                                                        </Grid>
                                                                        <Grid container item spacing={3.5} justifyContent="center" sx={{ mt: "10px" }}>
                                                                            <Grid item>
                                                                                <label>
                                                                                    <input hidden type="file" accept="image/*" onChange={(event, value) => handleImageChange(event, value, index)} onClick={event => event.target.value = null} />
                                                                                    <IconButton component="span" sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                                                                        <AddPhotoAlternateOutlinedIcon sx={{ width: 35, height: 35 }} />
                                                                                    </IconButton>
                                                                                </label>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <label>
                                                                                    <input hidden type="file" accept="image/*" capture="environment" onChange={(event, value) => handleImageChange(event, value, index)} onClick={event => event.target.value = null} />
                                                                                    <IconButton component="span" sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                                                                        <AddAPhotoOutlinedIcon sx={{ width: 35, height: 35 }} />
                                                                                    </IconButton>
                                                                                </label>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            }
                                                        </Grid>
                                                        <Grid container item direction="column" spacing={2.5} xs={12}>
                                                            <Grid item ref={selectedItemIndex === index ? itemNameRef : null}>
                                                                <Autocomplete
                                                                    {...autocompleteParams}
                                                                    options={itemNameOptions}
                                                                    renderInput={params => (<TextField
                                                                        {...params}
                                                                        label="Item name"
                                                                        value={item.name}
                                                                        inputProps={{ ...params.inputProps, autoCapitalize: "words" }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        helperText="Example: Air Hose"
                                                                        onFocus={() => setSelectedItemIndex(index)}
                                                                    />)}
                                                                    onInputChange={(event, value) => handleItemChange("name", value, index)}
                                                                    inputValue={item.name}
                                                                    onOpen={() => {
                                                                        if (isTouchScreenDevice()) {
                                                                            setTimeout(() => {
                                                                                itemNameRef.current.style.marginBottom = "90vh";
                                                                                itemNameRef.current.scrollIntoView();
                                                                            }, 100);
                                                                        }
                                                                    }}
                                                                    onClose={() => itemNameRef.current.style.marginBottom = "0"}
                                                                />
                                                            </Grid>
                                                            <Grid item ref={selectedItemIndex === index ? itemQuantityRef : null}>
                                                                <Autocomplete
                                                                    {...autocompleteParams}
                                                                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                                                                    filterOptions={options => options}
                                                                    renderInput={params => (<TextField
                                                                        {...params}
                                                                        label="Quantity"
                                                                        value={String(item.quantity)}
                                                                        inputProps={{ ...params.inputProps, autoCapitalize: "none" }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        onFocus={() => setSelectedItemIndex(index)}
                                                                    />)}
                                                                    onInputChange={(event, value) => handleItemChange("quantity", value, index)}
                                                                    inputValue={String(item.quantity)}
                                                                    onOpen={() => {
                                                                        if (isTouchScreenDevice()) {
                                                                            setTimeout(() => {
                                                                                itemQuantityRef.current.style.marginBottom = "90vh";
                                                                                itemQuantityRef.current.scrollIntoView();
                                                                            }, 100);
                                                                        }
                                                                    }}
                                                                    onClose={() => itemQuantityRef.current.style.marginBottom = "0"}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container alignContent="center" xs={2}>
                                                        {index > 0 && <IconButton tabIndex={-1} onClick={() => handleRemoveItem(index)}><RemoveCircleIcon /></IconButton>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                <Grid container item sx={{ mt: 2.5 }}>
                                    <Button
                                        onClick={handleAddItem}
                                        variant="outlined"
                                        sx={{ height: "45px", width: "150px", color: "#495464" }}
                                        component="span"
                                    >
                                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                                        Item
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item sx={{ mt: 1.5 }}>
                            <Grid container item spacing={2.5} alignItems="center">
                                <Grid container item alignItems="center">
                                    <Typography {...headingParams}>Delete Entry</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item sx={{ justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                                <Grid item>
                                    <Typography>Would you like to delete this entry?</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                    <Button disabled={disableSubmitButton} type="submit" sx={{ width: "100%", marginTop: "20px", height: "45px", marginBottom: "35px" }}>Update</Button>
                </form >
            </Grid >
            {openDelete && <DeleteDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} type="checkout" />}
        </Grid >
    );
};

export default EditCheckout;