import './App.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useContext, useEffect, useCallback } from 'react';
import { UserContext } from './context/UserContext';
import axios from 'axios';
import theme from './themes/theme';
import Login from './pages/Login';
import Main from './pages/Main';
import Settings from './pages/Settings';
import AddCheckout from './pages/AddCheckout';
import EditCheckout from './pages/EditCheckout';
import Layout from './components/Layout/Layout';

function App() {
  const [userContext, setUserContext] = useContext(UserContext);
  const location = useLocation();

  const verifyUser = useCallback(() => {
    const url = process.env.REACT_APP_API_URL + '/users/refreshtoken';
    const body = {};
    const config = { withCredentials: true };

    axios.post(url, body, config)
      .then(res => {
        setUserContext(userContext => ({ ...userContext, ...res.data }));
      })
      .catch(err => {
        return <Navigate to='/' />;
      });
  }, [setUserContext]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  useEffect(() => {
    if (userContext.lastReloaded
      && userContext.lastUpdated
      && new Date(userContext.lastReloaded) < new Date(userContext.lastUpdated)
      && new Date(userContext.lastUpdated) < new Date()
    ) {
      window.location.reload();
    }
  }, [location, userContext]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Routes>
        {!userContext.token ?
          <Route>
            <Route index element={<Login />} />
            <Route path='*' element={null} />
          </Route>
          :
          <Route element={<Layout />}>
            <Route index element={<Navigate to='main' />} />
            <Route path='main' element={<Main />} />
            <Route path='settings' element={<Settings />} />
            <Route path='addcheckout' element={<AddCheckout />} />
            <Route path='editcheckout/:_id' element={<EditCheckout />} />
          </Route>
        }
      </Routes>
    </ThemeProvider>
  );
}

export default App;