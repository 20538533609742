import { Grid, Button, Chip, Autocomplete, TextField, Typography, FormControlLabel, Checkbox, RadioGroup, Paper, IconButton, Radio, Modal, FormGroup } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { UserContext } from "../../context/UserContext";
import dayjs from "dayjs";
import { axiosInstance } from "../../utils/utils";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import ItemModal from "../Common/ItemModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CheckoutsTable = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [rowCount, setRowCount] = useState(0);
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [data, setData] = useState([]);
    const [sort, setSort] = useState(window.localStorage.getItem("checkoutsSort") !== null ? window.localStorage.getItem("checkoutsSort") : "updatedAt:desc");
    const [search, setSearch] = useState(userContext.checkoutsTableSearch ? userContext.checkoutsTableSearch : "");
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(userContext.active !== undefined ? userContext.active : false);
    const [completed, setCompleted] = useState(userContext.completed !== undefined ? userContext.completed : false);
    const navigate = useNavigate();
    const [imageWidth, setImageWidth] = useState("200px");
    const mobileWidth = 932;
    const [openItemModal, setOpenItemModal] = useState(false);
    const [item, setItem] = useState();
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [listening, setListening] = useState(false);
    const [selectedCheckout, setSelectedCheckout] = useState();
    const [searchOptions, setSearchOptions] = useState([]);
    const searchRef = useRef();

    useEffect(() => {
        if (!listening) {
            const eventSource = new EventSource(process.env.REACT_APP_API_URL + "/checkouts/updates");

            eventSource.onmessage = event => {
                setUpdating(true);
            };

            return () => eventSource.close();
        }

        setListening(true);
    }, [listening, setUpdating]);

    useEffect(() => {
        if (!props.topRef.current) return;

        if (showFilterMenu) {
            props.topRef.current.scrollIntoView();
        }
    }, [showFilterMenu, props.topRef]);

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    };

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (windowDimensions.width <= mobileWidth) {
            setImageWidth("100%");
        } else {
            setImageWidth("200px");
        }
    }, [windowDimensions]);

    const handleClickEntry = (event, _id) => {
        if (event.target.tagName === "INPUT"
            || event.target.tagName === "SPAN"
            || event.target.tagName === "LABEL"
            || event.target.tagName === "A") {

            return;
        }

        navigate("/editcheckout/" + _id);
    };

    const handlePageChange = (newPaginationModel, scrollToTop = true) => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("checkoutsPageSize", newPaginationModel.pageSize);
        setUserContext(userContext => ({ ...userContext, checkoutsPage: newPaginationModel.page }));

        if (scrollToTop && props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView();
        }
    };

    const getImage = useCallback(async image => {
        if (!image) {
            return null;
        }

        try {
            const url = process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
            const config = { responseType: "arraybuffer" };
            const fetchRes = await axiosInstance.get(url, config);
            const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
            return URL.createObjectURL(blob);
        }
        catch (err) {
            // Do nothing
        }
    }, [userContext.token]);

    useEffect(() => {
        const fetchData = async () => {
            if (props.paginationModel.pageSize === 0) {
                return;
            }

            let url = process.env.REACT_APP_API_URL + "/checkouts?page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (active && !completed) {
                url += "&completed=" + false;
            } else if (completed && !active) {
                url += "&completed=" + true;
            } else if (!completed && !active) {
                url += "&completed=" + false;
            }

            if (sort) {
                url += "&sort=" + sort;
            }

            if (search) {
                url += "&search=" + search;
            }

            try {
                const res = await axiosInstance.get(url, config);
                let updatedData = [...res.data.checkouts];

                for (const checkout of updatedData) {
                    for (const item of checkout.items) {
                        if (item.image) {
                            const fetchedImage = await getImage(process.env.REACT_APP_THUMBNAIL_PREFIX + item.image);
                            item.fetchedImage = fetchedImage;
                        }
                    };
                };

                setData(updatedData);
                setRowCount(res.data.total);
                setLoading(false);
                setUpdating(false);
            } catch (err) {
                // Do nothing
            }
        };

        if (loading || updating) {
            fetchData();
        }
    }, [userContext, setUserContext, sort, search, props.paginationModel, loading, updating, active, completed, getImage]);

    useEffect(() => {
        setRowCountState(prevRowCountState => rowCount !== undefined ? rowCount : prevRowCountState);
    }, [rowCount, setRowCountState]);

    const handleClickImage = (item) => {
        setItem(item);
    };

    useEffect(() => {
        if (item) {
            setOpenItemModal(true);
        }
    }, [item]);

    const handleClickStatus = (checkout) => {
        const items = [];

        for (const item of checkout.items) {
            items.push({ ...item });
        }

        setSelectedCheckout({ ...checkout, items: items });
    };

    useEffect(() => {
        if (selectedCheckout) {
            setOpenStatusModal(true);
        }
    }, [selectedCheckout]);

    const handleChangeStatus = (value, index) => {
        const updatedSelectedCheckout = { ...selectedCheckout };
        updatedSelectedCheckout.items[index].returned = value;
        updatedSelectedCheckout.items[index].modified = true;
        setSelectedCheckout(updatedSelectedCheckout);
    };

    const handleUpdateStatuses = async event => {
        event.preventDefault();

        const url = process.env.REACT_APP_API_URL + "/checkouts/" + selectedCheckout._id + "?timestamps=false&update_images=false";
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const payload = selectedCheckout;

        try {
            await axiosInstance.postForm(url, payload, config);
            setSelectedCheckout();
        } catch (err) {
            // Do nothing
        }

        setOpenStatusModal(false);
    };

    const rows = data;

    const columns = [{
        field: "_id",
        headerName: "Checkout",
        flex: 1,
        renderCell: params => {
            return (
                <Paper
                    key={params.row._id}
                    sx={{
                        backgroundColor: "#ffffff",
                        minHeight: "165px",
                        padding: "12px",
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "none",
                        width: "100%",
                    }}
                >
                    <Grid container item>
                        <Grid container item direction="row" justifyContent="space-between" wrap="nowrap">
                            <Grid item flex={1} sx={{ pr: "12px" }}>
                                <Typography sx={{ cursor: "pointer", width: "fit-content", fontWeight: "800", fontSize: "17px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    {params.row.name}
                                </Typography>
                                <Typography sx={{ whiteSpace: "pre-line", color: "rgba(0, 0, 0, 0.75)", cursor: "pointer", width: "fit-content", fontSize: "15px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    {params.row.project}
                                </Typography>
                            </Grid>
                            <Grid item justifyContent="flex-end">
                                <Chip label={params.row.completed ? "Completed" : "Set status"} variant="outlined" onClick={() => handleClickStatus(params.row)} />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)", width: "fit-content", marginTop: "5px", fontSize: "15px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                {params.row.date && <><b>Date:</b> {dayjs(params.row.date).format("ddd, MM/DD/YYYY hh:mm a")}</>}
                            </Typography>
                            {params.row.items?.length > 0 &&
                                <Typography sx={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontSize: "15px", mt: "5px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    <b>Items: </b>
                                    {params.row.items.map((item, index) => {
                                        if (index > 0) {
                                            return ", " + (item.quantity ? "(" + item.quantity + ") " : null) + item.name;
                                        } else {
                                            return (item.quantity ? "(" + item.quantity + ") " : null) + item.name;
                                        }
                                    })}
                                </Typography>
                            }
                            {params.row.notes &&
                                <Typography sx={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontSize: "15px", mt: "5px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    <b>Notes:</b> {params.row.notes}
                                </Typography>
                            }
                        </Grid>
                        <Grid container item spacing={1.5} sx={{ marginTop: "5px" }}>
                            {params.row.items.some(item => item.image) &&
                                params.row.items.map((item, index) => {
                                    return (item.image &&
                                        <Grid item key={index} xs={windowDimensions.width <= mobileWidth ? 6 : null}>
                                            <div
                                                style={{ position: "relative" }}
                                                onClick={() => handleClickImage(item)}
                                            >
                                                <img
                                                    src={item.fetchedImage}
                                                    alt=""
                                                    width={imageWidth}
                                                    style={{ objectFit: "cover", cursor: "pointer", aspectRatio: 1 }}
                                                />
                                                {item.returned &&
                                                    <div style={{ position: "absolute", top: "5px", left: "calc(100% - 30px)" }}>
                                                        <CheckCircleIcon sx={{ color: "rgba(255, 255, 255, 0.9)" }} />
                                                    </div>
                                                }
                                            </div>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <Grid container item direction="column" justifyContent="flex-end" sx={{ mt: 1.5 }}>
                            {params.row.createdName || params.row.createdBy ?
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.4)", fontSize: "12px", fontStyle: "italic" }}>
                                    Created {dayjs(params.row.createdAt).format("MMM DD, YYYY")} by {params.row.createdBy.charAt(0).toUpperCase() + params.row.createdBy.substring(1)}
                                </Typography>
                                :
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.4)", fontSize: "12px", fontStyle: "italic" }}>
                                    Created {dayjs(params.row.createdAt).format("MMM DD, YYYY")}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Paper >
            );
        }
    }];

    useEffect(() => {
        setUserContext(userContext => ({ ...userContext, checkoutsTableSearch: search }));
    }, [setUserContext, search]);

    const handleSort = event => {
        setSort(event.target.value);

        if (event.target.value) {
            window.localStorage.setItem("checkoutsSort", event.target.value);
        } else {
            window.localStorage.removeItem("checkoutsSort");
        }
    };

    const handleFilter = event => {
        if (event.target.name === "active") {
            setActive(!active);
            setUserContext(userContext => ({ ...userContext, active: !active }));
        } else if (event.target.name === "completed") {
            setCompleted(!completed);
            setUserContext(userContext => ({ ...userContext, completed: !completed }));
        }
    };

    const handleClearFilters = () => {
        setActive(false);
        setCompleted(false);
        setUserContext(userContext => ({ ...userContext, active: false, completed: false }));
    };

    useEffect(() => {
        setLoading(true);
    }, [props.paginationModel, search, active, completed, sort]);

    const apiRef = useGridApiRef();

    useEffect(() => {
        const date = dayjs(new Date()).subtract(5, "month").toISOString().substring(0, 10);
        let completedParam = undefined;

        if (active && !completed) {
            completedParam = false;
        } else if (completed && !active) {
            completedParam = true;
        } else if (!completed && !active) {
            completedParam = false;
        }

        const fetchNameOptions = async () => {
            let url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=name&start_date=" + date;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (completedParam !== undefined) {
                url += "&completed=" + completed;
            }

            try {
                const res = await axiosInstance.get(url, config);
                return res.data;
            } catch (err) {
                // Do nothing
            }
        };

        const fetchProjectOptions = async () => {
            let url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=project&start_date=" + date;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (completedParam !== undefined) {
                url += "&completed=" + completed;
            }

            try {
                const res = await axiosInstance.get(url, config);
                return res.data;
            } catch (err) {
                // Do nothing
            }
        };

        const fetchItemNameOptions = async () => {
            let url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=itemName&start_date=" + date;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (completedParam !== undefined) {
                url += "&completed=" + completed;
            }

            try {
                const res = await axiosInstance.get(url, config);
                return res.data;
            } catch (err) {
                // Do nothing
            }
        };

        const fetchOptions = async () => {
            const fetchedSearchOptions = new Set();

            // Fetch person name options
            let options = await fetchNameOptions();

            if (options) {
                options.forEach(option => fetchedSearchOptions.add(option));
            }

            // Fetch project options
            options = await fetchProjectOptions();

            if (options) {
                options.forEach(option => fetchedSearchOptions.add(option));
            }

            // Fetch item name options
            options = await fetchItemNameOptions();

            if (options) {
                options.forEach(option => fetchedSearchOptions.add(option));
            }

            setSearchOptions(Array.from(fetchedSearchOptions).sort());
        };

        fetchOptions();
    }, [userContext, setUserContext, active, completed, updating]);

    const isTouchScreenDevice = () => {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (err) {
            return false;
        }
    };

    return (
        <>
            <Grid container direction="column" sx={{ marginBottom: "25px" }}>
                <Grid container item direction="row" spacing={2.5} sx={{ justifyContent: "center", alignItems: "center", paddingBottom: "25px" }} ref={searchRef}>
                    <Grid item flex={1} sx={{ maxWidth: "365px" }}>
                        <Autocomplete
                            options={searchOptions}
                            renderInput={params => (<TextField {...params} label="Search" />)}
                            onChange={(event, value) => setSearch(value)}
                            value={search ? search : null}
                            freeSolo
                            autoSelect
                            autoHighlight
                            sx={{ width: "100%" }}
                            blurOnSelect
                            onOpen={() => {
                                if (isTouchScreenDevice()) {
                                    setTimeout(() => {
                                        searchRef.current.style.marginBottom = "90vh";
                                        searchRef.current.scrollIntoView();
                                    }, 100);
                                }
                            }}
                            onClose={() => searchRef.current.style.marginBottom = "0"}
                        />
                    </Grid>
                    <Grid item justifyItems="center">
                        <IconButton
                            onClick={() => setShowFilterMenu(!showFilterMenu)}
                            sx={{
                                backgroundColor: showFilterMenu ? "rgba(0, 0, 0, 0.1)" : "transparent",
                                ":hover": {
                                    backgroundColor: showFilterMenu ? "rgba(0, 0, 0, 0.1)" : null
                                }
                            }}
                        >
                            <FilterAltOutlinedIcon sx={{ width: 33, height: 33, color: "#495464" }} />
                        </IconButton>
                    </Grid>
                </Grid>
                {showFilterMenu &&
                    <Paper
                        sx={{
                            backgroundColor: "transparent",
                            padding: "12px",
                            justifyContent: "center",
                            boxShadow: "none",
                            width: "100%",
                            marginBottom: "25px",
                            border: "1px dotted #495464",
                            overflow: "hidden"
                        }}
                    >
                        <Grid container item direction="column" alignItems="center">
                            <Grid container item justifyContent="flex-end">
                                <IconButton onClick={() => setShowFilterMenu(false)}><CloseIcon /></IconButton>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" spacing={2.5}>
                                <Grid item sx={{ mr: "25px" }}>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold", mb: 2.5 }}>
                                        Filter
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="active"
                                            checked={active}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="Active"
                                        />
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="completed"
                                            checked={completed}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="Completed"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold", mb: 2.5 }}>
                                        Sort
                                    </Typography>
                                    <RadioGroup value={sort} onChange={handleSort}>
                                        <FormControlLabel value="name:asc" control={<Radio />} label="A-Z" sx={{ mb: 1 }} />
                                        <FormControlLabel value="date:desc" control={<Radio />} label="Date" sx={{ mb: 1 }} />
                                        <FormControlLabel value="createdAt:desc" control={<Radio />} label="Latest" sx={{ mb: 1 }} />
                                        <FormControlLabel value="updatedAt:desc" control={<Radio />} label="Updated" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" sx={{ mt: 1.5, mb: 2 }}>
                                <Grid item sx={{ mr: "25px" }}>
                                    <Button
                                        onClick={handleClearFilters}
                                        variant="outlined"
                                        sx={{ height: "45px", width: "125px" }}
                                        component="span"
                                    >
                                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><ClearIcon sx={{ fontSize: 20 }} /></span>
                                        Clear
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => setShowFilterMenu(false)}
                                        sx={{
                                            height: "45px",
                                            width: "125px",
                                            backgroundColor: "rgba(42, 157, 143, 0.6)",
                                            color: "#495464",
                                            "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" }
                                        }}
                                    >
                                        Done
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                <Grid container item>
                    <Button
                        onClick={() => navigate("/addcheckout")}
                        size="medium"
                        sx={{ height: "45px", width: "120px" }}
                    >
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                        Add
                    </Button>
                </Grid>
                <Grid container item spacing={1} sx={{ marginTop: active || completed ? "25px" : 0 }}>
                    {active && <Grid item><Chip label="Active" variant="outlined" onDelete={() => handleFilter({ target: { name: "active" } })} deleteIcon={<ClearIcon />} /></Grid>}
                    {completed && <Grid item><Chip label="Completed" variant="outlined" onDelete={() => handleFilter({ target: { name: "completed" } })} deleteIcon={<ClearIcon />} /></Grid>}
                </Grid>
            </Grid>
            <Grid container direction="column">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowHeight={() => "auto"}
                    autoHeight
                    disableRowSelectionOnClick
                    disableColumnMenu
                    getRowId={row => row._id}
                    slots={{
                        noRowsOverlay: () => {
                            return <Typography variant="body1" align="center" sx={{ color: "#495464", pt: "100px" }}>No checkouts found ;-(</Typography>;
                        }
                    }}
                    pageSizeOptions={[15, 30, 50, 100]}
                    paginationMode="server"
                    paginationModel={props.paginationModel}
                    onPaginationModelChange={handlePageChange}
                    rowCount={rowCountState}
                    apiRef={apiRef}
                    loading={loading}
                    sx={{
                        fontSize: 16,
                        border: 0,
                        "& .MuiDataGrid-cell": {
                            padding: "0 0 20px 0",
                            border: 0,
                            "&:focus-within, &:focus": { outline: "none" }
                        },
                        "& .MuiDataGrid-footerContainer": { border: 0 },
                        "& .MuiDataGrid-columnHeaders": { display: "none" },
                        "& .MuiDataGrid-row:hover": { borderRadius: "0px", backgroundColor: "transparent" },
                        "& .MuiDataGrid-overlayWrapper": { height: "100vh !important" }
                    }}
                />
            </Grid>
            <Modal open={openStatusModal} onClose={() => setOpenStatusModal(false)}>
                <Paper
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 400,
                        maxHeight: 500,
                        width: "80%",
                        overflow: "auto",
                        padding: "12px"
                    }}
                >
                    <form onSubmit={handleUpdateStatuses}>
                        <Grid container item direction="column" alignItems="center">
                            <Grid container item justifyContent="flex-end">
                                <IconButton onClick={() => setOpenStatusModal(false)}><CloseIcon /></IconButton>
                            </Grid>
                            <Grid container item direction="column" alignItems="center">
                                <Grid item>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                                        Set Status
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ mt: 2 }}>
                                    <FormGroup>
                                        {selectedCheckout && selectedCheckout.items.map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    key={index}
                                                    checked={item.returned}
                                                    control={<Checkbox />}
                                                    label={(item.quantity ? "(" + item.quantity + ") " : null) + item.name}
                                                    sx={{ mb: index < selectedCheckout.items.length ? 1 : null }}
                                                    onChange={event => handleChangeStatus(event.target.checked, index)}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" sx={{ mt: 4, mb: 2 }}>
                                <Grid item>
                                    <Button type="submit" sx={{ height: "45px", width: "125px" }}>
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Modal>
            {openItemModal && <ItemModal openModal={openItemModal} setOpenModal={setOpenItemModal} setItem={setItem} item={item} />}
        </>
    );
};

export default CheckoutsTable;