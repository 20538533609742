import { Modal, Paper, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../../utils/utils";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";

const ItemModal = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [image, setImage] = useState();

    const handleClose = () => {
        props.setItem();
        props.setOpenModal(false);
    };

    useEffect(() => {
        const getImage = async () => {
            try {
                const url = process.env.REACT_APP_API_URL + "/images/" + props.item.image;
                const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };
                const fetchRes = await axiosInstance.get(url, config);
                const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
                setImage(URL.createObjectURL(blob));
            }
            catch (err) {
                // Do nothing
            }
        };

        getImage();
    }, [setUserContext, userContext, props.item.image]);

    return (
        <Modal open={props.openModal} onClose={handleClose}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxHeight: "96%",
                    width: "96%",
                    maxWidth: 900,
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 2.5%" }}>
                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                </Grid>
                <Grid container item alignItems="center" direction="column" sx={{ padding: "0% 4% 4% 4%", minHeight: "87%" }}>
                    {image && <img src={image} alt="" width="100%" />}
                </Grid>
            </Paper>
        </Modal >
    );
};

export default ItemModal;