import { Grid, TextField, Button, Typography } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import ErrorBox from "../Common/ErrorBox";
import PasswordAdornment from "../Common/PasswordAdornment";
import { UserContext } from "../../context/UserContext";
import { refreshToken } from "../../utils/utils";
import { axiosInstance } from "../../utils/utils";

const UserInfo = props => {
    const gridItemParams = { sx: { width: "100%" } };
    const [userContext, setUserContext] = useContext(UserContext);
    const [inputs, setInputs] = useState({ username: userContext.username, currentPassword: "", newPassword: "", confirmNewPassword: "" });
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [disableButton, setDisableButton] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const textFieldParams = { fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value), InputLabelProps: { shrink: true } };
    const headingParams = { variant: "body1", sx: { fontWeight: "bold", color: "#495464" } };

    const handleChange = (name, value) => setInputs(inputs => ({ ...inputs, [name]: value }));

    const handleSubmit = event => {
        event.preventDefault();

        const body = { username: inputs.username, password: inputs.currentPassword, newPassword: inputs.newPassword };
        const url = process.env.REACT_APP_API_URL + "/users/" + userContext._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.post(url, body, config)
            .then(res => {
                props.setSnackbarMessage("User info updated");
                props.setOpenSnackbar(true);
                setInputs({ username: "", currentPassword: "", newPassword: "", confirmNewPassword: "" });
                setErrorMessage("");
                setShowErrorMessage(false);
                refreshToken(setUserContext);
                document.activeElement.blur();
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    useEffect(() => {
        const validInputs = () => {
            if (inputs.username !== userContext.username && !inputs.newPassword) {
                return inputs.currentPassword && inputs.username;
            } else {
                return (
                    inputs.currentPassword
                    && inputs.newPassword
                    && inputs.confirmNewPassword
                    && inputs.newPassword === inputs.confirmNewPassword
                    && inputs.username
                );
            }
        };

        setDisableButton(!validInputs());
    }, [inputs, userContext]);

    useEffect(() => setInputs(inputs => ({ ...inputs, "username": userContext.username })), [userContext]);

    return (
        <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "500px" }}>
            <Grid container item direction="column" spacing={2.5}>
                <Grid container item spacing={2.5} alignItems="center">
                    <Grid container item alignItems="center">
                        <Typography {...headingParams}>User</Typography>
                    </Grid>
                </Grid>
                <Grid container item sx={{ mt: 1, mb: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Current user</Typography>
                </Grid>
                <Grid container item {...gridItemParams}>
                    <TextField {...textFieldParams} name="username" label="Username" value={inputs.username} inputProps={{ autoCapitalize: "none" }} />
                </Grid>
                <Grid container item {...gridItemParams}>
                    <TextField
                        {...textFieldParams}
                        type={!showPassword ? "password" : "text"}
                        InputProps={{ endAdornment: <PasswordAdornment showPassword={showPassword} setShowPassword={setShowPassword} /> }}
                        name="currentPassword"
                        label="Current password"
                        value={inputs.currentPassword}
                    />
                </Grid>
                <Grid container item sx={{ mt: 1, mb: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Change password</Typography>
                </Grid>
                <Grid container item {...gridItemParams}>
                    <TextField
                        {...textFieldParams}
                        type={!showNewPassword ? "password" : "text"}
                        InputProps={{ endAdornment: <PasswordAdornment showPassword={showNewPassword} setShowPassword={setShowNewPassword} /> }}
                        name="newPassword"
                        label="New password"
                        value={inputs.newPassword}
                    />
                </Grid>
                <Grid container item {...gridItemParams} sx={{ mb: 2.5 }}>
                    <TextField
                        {...textFieldParams}
                        type={!showConfirmNewPassword ? "password" : "text"}
                        InputProps={{ endAdornment: <PasswordAdornment showPassword={showConfirmNewPassword} setShowPassword={setShowConfirmNewPassword} /> }}
                        name="confirmNewPassword"
                        label="Confirm new password"
                        value={inputs.confirmNewPassword}
                    />
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} align={"left"} />
                <Grid container item {...gridItemParams}>
                    <Button
                        sx={{ height: "45px", width: "120px" }}
                        type="submit"
                        disabled={disableButton}
                    >
                        Update
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default UserInfo;