import { Grid, TextField, Button, Typography, Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ErrorBox from "../Common/ErrorBox";
import PasswordAdornment from "../Common/PasswordAdornment";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils";

const Users = props => {
    const [users, setUsers] = useState([]);
    const [removeUserIds, setRemoveUserIds] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [newUser, setNewUser] = useState({ username: "", password: "", admin: false });
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const textFieldParams = { fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value), InputLabelProps: { shrink: true } };
    const headingParams = { variant: "body1", sx: { fontWeight: "bold", color: "#495464" } };

    const handleChange = (name, value) => setNewUser(newUser => ({ ...newUser, [name]: value }));

    const handleSubmit = event => {
        event.preventDefault();

        let newUsers = [];
        let currentUsers = [];

        for (const user of users) {
            if (!("_id" in user)) {
                newUsers = [...newUsers, user];
            } else {
                delete user.username;
                currentUsers = [...currentUsers, user];
            }
        }

        const requests = [];
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        currentUsers.forEach(user => {
            const url = process.env.REACT_APP_API_URL + "/users/" + user._id;
            const body = user;
            const request = axiosInstance.post(url, body, config);
            requests.push(request);
        });

        newUsers.forEach(user => {
            const url = process.env.REACT_APP_API_URL + "/users";
            const body = user;
            const request = axiosInstance.post(url, body, config);
            requests.push(request);
        });

        removeUserIds.forEach(_id => {
            const url = process.env.REACT_APP_API_URL + "/users/" + _id;
            const request = axiosInstance.delete(url, config);
            requests.push(request);
        });

        Promise.all(requests)
            .then(() => {
                props.setSnackbarMessage("Users updated");
                props.setOpenSnackbar(true);
                setUsers([]);
                setRemoveUserIds([]);
                setErrorMessage("")
                setShowErrorMessage(false);
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
                document.activeElement.blur();
            })
            .catch(err => {
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    const handleAdd = () => {
        if (newUser.username && newUser.password) {
            for (const user of users) {
                if (user.username === newUser.username) {
                    return;
                }
            }

            setUsers(users => [...users, newUser]);
            setNewUser({ username: "", password: "", admin: false });
        }
    };

    const handleRemove = username => {
        setUsers(users => users.filter(user => {
            if (user.username === username) {
                if ("_id" in user) {
                    setRemoveUserIds(removeUserIds => [...removeUserIds, user._id]);
                }

                return false;
            }

            return true;
        }));
    };

    const handleCheck = (event, username) => {
        const updatedUsers = [...users];
        const index = updatedUsers.findIndex(user => user.username === username);
        updatedUsers[index].admin = event.target.checked;

        setUsers(updatedUsers);
    };

    useEffect(() => {
        const fetchUsers = () => {
            const url = process.env.REACT_APP_API_URL + "/users";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config).then(res => setUsers(res.data)).catch(err => { });
        };

        fetchUsers();
    }, [userContext, setUserContext]);

    return (
        <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "500px" }}>
            <Grid container item direction="column" spacing={2.5}>
                <Grid container item spacing={2.5} alignItems="center" sx={{ mb: 1 }}>
                    <Grid container item alignItems="center">
                        <Typography {...headingParams}>Users</Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Table sx={{
                        "& .MuiTableCell-root": { border: 0, padding: 1, fontSize: "0.875rem" },
                        "& .MuiTableCell-head": { height: "30px", verticalAlign: "top", fontWeight: "bold" }
                    }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Admin</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{ width: "35%" }}>{user.username}</TableCell>
                                    <TableCell sx={{ width: "20%" }}>
                                        <FormControlLabel
                                            disabled={userContext.username === user.username}
                                            checked={user.admin}
                                            control={<Checkbox />}
                                            sx={{ margin: 0 }}
                                            onChange={event => handleCheck(event, user.username)}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ width: "10%" }}>
                                        {userContext.username !== user.username &&
                                            <IconButton onClick={() => handleRemove(user.username)}><RemoveIcon /></IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid container item sx={{ mt: 1, mb: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Add user</Typography>
                </Grid>
                <Grid container item spacing={2.5} sx={{ mb: 2.5 }} wrap="nowrap">
                    <Grid container item spacing={2.5}>
                        <Grid container item>
                            <TextField {...textFieldParams} name="username" label="Username" value={newUser.username} inputProps={{ autoCapitalize: "none" }} />
                        </Grid>
                        <Grid container item>
                            <TextField
                                {...textFieldParams}
                                type={!showPassword ? "password" : "text"}
                                name="password"
                                label="Password"
                                value={newUser.password}
                                InputProps={{ endAdornment: <PasswordAdornment showPassword={showPassword} setShowPassword={setShowPassword} /> }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="center" sx={{ width: "fit-content" }}>
                        <IconButton disabled={!(newUser.username && newUser.password)} onClick={handleAdd}><AddIcon /></IconButton>
                    </Grid>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} align={"left"} />
                <Grid container item>
                    <Button
                        sx={{ height: "45px", width: "120px" }}
                        type="submit"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default Users;