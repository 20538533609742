import axios from "axios";
import dayjs from "dayjs";
import { useEffect } from "react";

export const getDaysAgo = date => {
    const diff = parseInt((new Date() - new Date(date)) / (1000 * 60 * 60 * 24), 10);

    if (diff === 0) {
        return "Today";
    } else if (diff === 1) {
        return "1 day ago";
    } else {
        return diff + " days ago";
    }
};

export const combineDateTime = (date, time) => {
    date = dayjs(date).format("YYYY-MM-DD");
    time = dayjs(time).format("HH:mm:ss.sssZ");
    return dayjs(`${date}T${time}`).toDate();
};

export const refreshToken = setUserContext => {
    const url = process.env.REACT_APP_API_URL + "/users/refreshtoken";
    const body = {};
    const config = { withCredentials: true };

    axios.post(url, body, config).then((res => setUserContext(userContext => ({ ...userContext, ...res.data })))).catch(err => { });
};

export const axiosInstance = axios.create();

let tokenRequest;

const resetTokenRequest = () => {
    tokenRequest = null;
};

const getToken = config => {
    if (!tokenRequest) {
        tokenRequest = new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + "/users/refreshtoken";
            const body = {};
            const configuration = { withCredentials: true };

            axios.post(url, body, configuration)
                .then(res => {
                    config.setUserContext(userContext => ({ ...userContext, ...res.data }));
                    config.headers.Authorization = "Bearer " + res.data.token;
                    return resolve(config);
                })
                .catch(err => {
                    return reject(err);
                });
        });

        tokenRequest.then(resetTokenRequest, resetTokenRequest);
    }

    return tokenRequest;
};

axiosInstance.interceptors.response.use((res) => res,
    (err) => {
        if (err.response && err.response.status === 401) {
            return getToken(err.config).then(res => {
                return axios(err.config);
            });
        } else {
            return Promise.reject(err);
        }
    }
);

export const usePullToRefresh = (ref, onTrigger) => {
    useEffect(() => {
        const el = ref.current;
        if (!el) return;

        const handleTouchStart = startEvent => {
            const el = ref.current;
            if (!el) return;
            const parentEl = el.parentNode;

            const initialY = startEvent.touches[0].clientY;

            const addPullIndicator = el => {
                const indicator = el.querySelector(".pull-indicator");

                if (indicator) {
                    if (indicator.classList.contains("flip")) {
                        indicator.classList.remove("flip");
                    }

                    return;
                }

                const pullIndicator = document.createElement("div");
                pullIndicator.className = "pull-indicator";
                pullIndicator.innerHTML = "<span>↓</span>";
                el.appendChild(pullIndicator);
            };

            const removePullIndicator = el => {
                const pullIndicator = el.querySelector(".pull-indicator");

                if (pullIndicator) {
                    pullIndicator.remove();
                }
            };

            const flipArrow = el => {
                const pullIndicator = el.querySelector(".pull-indicator");

                if (pullIndicator && !pullIndicator.classList.contains("flip")) {
                    pullIndicator.classList.add("flip");
                }
            };

            const triggerThreshold = 450;
            const showIndicatorThreshold = 50;

            const handleTouchMove = moveEvent => {
                const el = ref.current;
                if (!el) return;

                const appr = x => {
                    const max = 128;
                    const k = 0.4;

                    return max * (1 - Math.exp((-k * x) / max));
                };

                const currentY = moveEvent.touches[0].clientY;
                const dy = currentY - initialY;
                if (dy < 0) return;

                el.style.transform = `translateY(${appr(dy)}px)`;

                if (dy > triggerThreshold) {
                    flipArrow(parentEl);
                } else if (dy > showIndicatorThreshold) {
                    addPullIndicator(parentEl);
                } else {
                    removePullIndicator(parentEl);
                }
            };

            const handleTouchEnd = endEvent => {
                const el = ref.current;
                if (!el) return;

                const onTransitionEnd = () => {
                    const el = ref.current;
                    if (!el) return;

                    el.style.transition = "";
                    el.removeEventListener("transitionend", onTransitionEnd);
                };

                el.style.transform = "translateY(0)";
                el.style.transition = "transform 0.2s";

                removePullIndicator(el.parentNode);

                const y = endEvent.changedTouches[0].clientY;
                const dy = y - initialY;

                if (dy > triggerThreshold) {
                    onTrigger();
                }

                el.addEventListener("transitionend", onTransitionEnd);

                el.removeEventListener("touchmove", handleTouchMove);
                el.removeEventListener("touchend", handleTouchEnd);
            };

            el.addEventListener("touchmove", handleTouchMove);
            el.addEventListener("touchend", handleTouchEnd);
        };

        el.addEventListener("touchstart", handleTouchStart);

        return () => el.removeEventListener("touchstart", handleTouchStart);
    }, [ref, onTrigger]);
};