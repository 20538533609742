import CheckoutsTable from "../components/Checkouts/CheckoutsTable";
import { Grid, Typography } from "@mui/material";
import TopBar from "../components/Layout/TopBar";
import { useContext, useRef, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import TheSnackbar from "../components/Common/TheSnackbar";
import { useLocation } from "react-router-dom";

const Main = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const topRef = useRef(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const location = useLocation();
    const subtitleRef = useRef(null);
    const [paginationModel, setPaginationModel] = useState({
        page: userContext.checkoutsPage ? userContext.checkoutsPage : 0,
        pageSize: window.localStorage.getItem("checkoutsPageSize") !== null ? parseInt(window.localStorage.getItem("checkoutsPageSize")) : 15
    });

    useEffect(() => {
        if (location.state?.goToFirstPage) {
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
            setUserContext(userContext => ({ ...userContext, checkoutsPage: 0 }));
        }

        if (location.state?.snackbarMessage) {
            setSnackbarMessage(location.state.snackbarMessage);
            setOpenSnackbar(true);
            window.history.replaceState({}, "");
        }
    }, [location, setUserContext]);

    return (
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "10px" }}>
                {userContext.name ?
                    <TopBar title={"Hi " + userContext.name} />
                    :
                    <TopBar title={"Hi " + userContext.username.charAt(0).toUpperCase() + userContext.username.slice(1).toLowerCase()} />
                }
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px", paddingTop: "15px" }} ref={subtitleRef}>
                <Typography color="black">See or add tool checkouts below</Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <CheckoutsTable subtitleRef={subtitleRef} topRef={topRef} paginationModel={paginationModel} setPaginationModel={setPaginationModel} />
            </Grid>
        </Grid>
    );
};

export default Main;